import homeContent from "../content/home";
import React from 'react';

import Button from "../atoms/button";
import SunFlower from '../assets/banner-flowers.jpeg'

const Home = ({ session }) => {

    const renderBeginBtn = () => {
        if (session !== null && session.success === true) {
            return (
                <Button path="/info" label={homeContent.startBtnLabel} />
            );
        } 
        return (
            <p><b>Sorry but we couldn't find the survey id</b></p>
        )
    };

    return (
    <div className="content">
        <h1>{homeContent.title}</h1>
        <div className="banner" style={{ backgroundImage: `url(${SunFlower})` }}></div>
        <div className="contentBlock">{homeContent.intro}</div>
        <div className="contentRow reverseOrder">
        <div className="contentBlock">{ renderBeginBtn() }</div>
         <div className="contentBlock">{homeContent.information}</div>
        </div> 
    </div>

    );
  };
  
  export default Home;