
const Answer = ( {id, label, classes, clickHandler} ) => {

    return (
        <div 
            key={ id }
            className={ classes }
            onClick={ () => clickHandler(id) }
            >
                { label }
        </div>
    )
};


export default Answer;
