import React from "react";
import Card from '../atoms/card';

const Cards = ( {category, card, colour} ) => {

    const renderCard = () => {
        if ( card === null ) return null;

        return (
            <Card id={card.id} statement={card.statement} category={category} colour={colour}/>
        )};
    
    return (
        <div className='cardList'>       
            {renderCard()}
        </div>
    );
}

export default Cards;