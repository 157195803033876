import React from 'react';
import infoContent from '../content/info';

import Button from "../atoms/button";

const Information = ({ session }) => {
    return (
    <div className="content">
        <h1>{infoContent.title}</h1>
        <div className="contentRow">
            <div className="contentBlock" style={{ fontWeight: 'bold'}} >{infoContent.gdprStatement}</div>
            <div className="contentBlock">{infoContent.gdprRights}</div>
        </div>
        <div className="contentRow">
            <div className="contentBlock" dangerouslySetInnerHTML={{ __html: infoContent.sessionStatement.replace("[[sessionID]]", session.sessionId )}}></div>
            <div className="contentBlock">
                <Button path="/about-you" label={infoContent.btnLabel} />
            </div>
        </div>

    </div>
    );

  };
  
  export default Information;