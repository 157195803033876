import React from "react";

const Progress = ( {total, index, colour} ) => {
   
    const fillerStyles = {
        width: `${((index) / total) * 100 }%`,
        backgroundColor: colour
    }

    return (
        <div className="progressBar">
            <div className="progress" style={fillerStyles}></div>
            <div className="label">{index} of {total}</div>
        </div>
    );
}

export default Progress;