
export async function getDemographicOptions(surveyId, passBack) {
    const response = await fetch(process.env.REACT_APP_API_URL + 'demographics/' + surveyId);
    const data = await response.json();
    if(data !== undefined) {
        passBack(data);
    }
}

export async function postDemographicResponses(data, callBack) {

    fetch(process.env.REACT_APP_API_URL + 'demographics', {
        method: 'POST',
         headers: {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json' 
        },

        body : JSON.stringify(data)
    })
    .then(response => response.json())
    .then(callBack())    
}