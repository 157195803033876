import { Link } from "react-router-dom";

const Button = ( {path, handleOnClick, label, customClass } ) => {
 
    let classes = "button";
    if (customClass != null) classes = "button "+ customClass;
    return (
            <Link className={classes} onClick={handleOnClick} to={path}>{label}</Link>
    );
}

export default Button;
