import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState,useEffect, useRef } from 'react';

import { initialiseSurvey, getSessionObj, createSessionCookie } from "./functions/session";

import Layout from "./pages/layout";
import NoPage from "./pages/404";
import Home from "./pages/home";
import Survey from "./pages/survey";
import Complete from "./pages/complete";
import AboutYou from "./pages/aboutyou";
import Information from "./pages/information";


function App() {

  const initialise = useRef(true);
  const calledInitialise = useRef(false);

  const [session, setSession] = useState( null );
  
  useEffect(() => {
    if (initialise.current) {
      initialise.current = false;
      let s = getSessionObj();
      setSession(s);
    }

  },[]);

  useEffect(() => {
    if (!calledInitialise.current) {
      if (session !== null && !session.success){
        calledInitialise.current = initialiseSurvey(setNewSession);
      }
  }
  
  },[session]);

  const setNewSession = (data)=> {
    setSession(data);
    if (data.success === true) {
      createSessionCookie(data);
    }
  }

  return (
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home session={session} />} />
            <Route path="info" element={<Information session={session} />} />
            <Route path="about-you" element={<AboutYou session={session} />} />
            <Route path="survey" element={<Survey session={session} />} />
            <Route path="complete" element={<Complete session={session} />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;