import React, { useState, useEffect } from 'react';
import storeKeys from '../data/storeKeys';
import { saveResults } from "../functions/session";


import Button from "../atoms/button";

import Cards from '../components/cards';
import Answers from '../components/answers';
import Progress from '../components/progress';

import AnswerData from '../data/answerData';
import CardData from '../data/cardData';
import CategoryData from '../data/categoryData';
import { useNavigate } from 'react-router-dom';

const Survey = ({ session }) => {
  const navigate = useNavigate();

  const answers = AnswerData;
  const cards = CardData;
  const categories = CategoryData;

  const [currentCard, setCurrentCard] = useState( 0 );
  const [answersChanged, setAnswersChanged] = useState( 0 );
  const [currentCategory, setCurrentCategory] = useState( 0 );
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);


  const setCardCookie = () => {
    window.localStorage.setItem(storeKeys.CurrentCard, currentCard);
  }

  useEffect(() => {
    setCardFromCookieOnFirstLoad();
    getCurrentCategory();
  })

  const setCardFromCookieOnFirstLoad = () => {
    if ( isFirstLoad ){
      setIsFirstLoad( false );
      let cookieCard = window.localStorage.getItem(storeKeys.CurrentCard);
      if ( cookieCard !== null ) {
        if (cookieCard === "-") navigate("/complete")
        else setCurrentCard( cookieCard * 1 );
      }
    }
    else { setCardCookie(); }
  }

  const getCurrentCategory = () => {
    let category = 0;
    for (let i = 0; i < categories.length; i++){
      if (categories[i].id === cards[currentCard].category) { category = i}
    }
    setCurrentCategory(category);
  }

  const handleAnswerClick = (answerId) => {
    let newResults = selectedAnswers;
    if(selectedAnswers.includes( answerId )) {
      const filtered = selectedAnswers.filter(answer => {
        return answer !== answerId;
      });
      newResults = filtered;
    }
    else { newResults.push( answerId ) }
    setSelectedAnswers(newResults);
    setAnswersChanged( answersChanged + 1 );
  }

  const handleNextCard = () => {
    saveResults({session: session.sessionId, statement: cards[currentCard].id, answers: selectedAnswers})
    let index = currentCard + 1;
    if ( index > cards.length -1 ) window.location.href = '/complete';
    changeCard( currentCard + 1 );
  }

  const renderNextButton = () => {
    if (selectedAnswers.length === 0) {
      return (
      <div className="cardChangeBtn disabled">
        <span>Next</span>
      </div>        
    )}
    else {
      return (
      <div className="cardChangeBtn" onClick={handleNextCard}>
        <span>Next</span>
      </div>        
    )}
  }

  const changeCard = ( index ) => {
    setCurrentCard( index );
    setSelectedAnswers( [] );
  }
  

    return (
        <div className="App">
          <header className="surveyHdr">
            <Button customClass="info" path="/info" label="Info" />
            <Progress total={cards.length} index={currentCard + 1} colour={categories[currentCategory].colour} />
            {renderNextButton()}
          </header>
          <div className="content">
            <Cards 
              category={categories[currentCategory]}
              card={ cards[currentCard] } 
              colour= {categories[currentCategory].colour} />
            <Answers answers={ answers } answerType={cards[currentCard].answerType} selectedAnswers={ selectedAnswers } handleAnswerClick={ handleAnswerClick } /> 
          </div>
        </div>
      );
  };
  
  export default Survey;