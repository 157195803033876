import Button from "../atoms/button";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

import { getDemographicOptions, postDemographicResponses } from "../functions/demographics";
import aboutYouContent from "../content/aboutYou";


const AboutYou = ({ session }) => {

    const navigate = useNavigate();

    const [demographics, setDemographics] = useState( {"ethnicity" : [],"gender" : [],"sexuality" : [],"countries" : [], "languages" : [] } );
    const [ethValue, setEthValue] = useState(null);
    const [genValue, setGenValue] = useState(null);
    const [sexValue, setSexValue] = useState(null);
    const [couValue, setCouValue] = useState(null);
    const [lanValue, setLanValue] = useState(null);

    useEffect(() => {
        if(session !== null && session.surveyId !== undefined){
            getDemographicOptions(session.surveyId, updateDemographicOptions);
        }
    },[session])
 
    const updateDemographicOptions = (data) => {
        setDemographics(data);
    }

    const validResponse = () => {
        let demoData = [];
        if (ethValue !== null) demoData.push(ethValue.value)
        if (genValue !== null) demoData.push(genValue.value)
        if (sexValue !== null) demoData.push(sexValue.value)
        if (couValue !== null) demoData.push(couValue.value)
        if (lanValue !== null) demoData.push(lanValue.value)
        return demoData;
    }

    const proceedOnclick = (e) => {
        e.preventDefault();
        postDemographicResponses(
            {session: session.sessionId,responses: validResponse()}, 
            ()=>{ navigate("/survey") } 
        );    
    }

    const renderSelect = (label, options, value, setValue) => {
        if(options && options.length > 0)
        {
            return (
                <div className="formField">
                    <div className="demoLabel">{label}</div>
                    <Select className="select" defaultValue={value} onChange={setValue} options={options} />
                </div>
            )
        }
    }

    return (
        <div className="App">
            <header>
                <Button customClass="info" path="/info" label="Info" />
            </header>
            <div className="content">
                <h1>{aboutYouContent.title}</h1>
                <div className="contentBlock">{aboutYouContent.why}</div>
                <div className="demographicSelects">
                    {renderSelect("Ethnicity", demographics.ethnicity, ethValue, setEthValue)}
                    {renderSelect("Gender", demographics.gender, genValue, setGenValue)}
                    {renderSelect("Sexuality", demographics.sexuality, sexValue, setSexValue)}
                    {renderSelect("Country of residence", demographics.countries, couValue, setCouValue)}
                    {renderSelect("First language", demographics.languages, lanValue, setLanValue)}
                </div>
                <div className="demoFtr">
                    <Button customClass="proceed" handleOnClick={ proceedOnclick } path="/survey" label="Start Survey" />
                </div>
            </div>
    </div>

    );

  };
  
  export default AboutYou;