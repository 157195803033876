const data = [

        { id: "s1", category : 'Services', answerType : "timeline", statement :  'Someone listened to me and took me seriously'},
        { id: "s2", category : 'Services', answerType : "timeline", statement :  'I received therapy which helped'},
        { id: "s3", category : 'Services', answerType : "timeline", statement :  'I received therapy which did not help'},
        { id: "s4", category : 'Services', answerType : "timeline", statement :  'I talked to a friend which helped'},
        { id: "s5", category : 'Services', answerType : "timeline", statement :  'I talked to a friend which did not help'},
        { id: "s6", category : 'Services', answerType : "timeline", statement :  'I talked to my caregiver which helped'},
        { id: "s7", category : 'Services', answerType : "timeline", statement :  'I talked to my caregiver which did not help'},
        { id: "s8", category : 'Services', answerType : "timeline", statement :  'I saw my GP which helped'},
        { id: "s9", category : 'Services', answerType : "timeline", statement :  'I saw my GP which did not help'},
        { id: "s10", category : 'Services', answerType : "timeline", statement :  'I phoned a helpline which helped'},
        { id: "s11", category : 'Services', answerType : "timeline", statement :  'I phoned a helpline which did not help'},
        { id: "s12", category : 'Services', answerType : "timeline", statement :  'I went to counselling which helped'},
        { id: "s13", category : 'Services', answerType : "timeline", statement :  'I went to counselling which did not help'},
        { id: "s14", category : 'Services', answerType : "timeline", statement :  'I read a self-help book - it helped'},
        { id: "s15", category : 'Services', answerType : "timeline", statement :  'I read a self-help book - it did not help'},
        { id: "s16", category : 'Services', answerType : "timeline", statement :  'I talked to a teacher which helped'},
        { id: "s17", category : 'Services', answerType : "timeline", statement :  'I talked to a teacher which did not help'},
        { id: "s18", category : 'Services', answerType : "timeline", statement :  'I talked to a social worker which helped'},

        { id: "a1", category : 'Afterwards', answerType : "confirm", statement :  'I went to hospital for overdose or self-injury'},
        { id: "a2", category : 'Afterwards', answerType : "confirm", statement :  'I felt worse after self-harm'},
        { id: "a3", category : 'Afterwards', answerType : "confirm", statement :  'Self-harm stopped me from killing myself'},
        { id: "a4", category : 'Afterwards', answerType : "confirm", statement :  'A+E staff were friendly and understanding'},
        { id: "a5", category : 'Afterwards', answerType : "confirm", statement :  'A+E staff were not friendly and understanding'},
        { id: "a6", category : 'Afterwards', answerType : "confirm", statement :  'I felt better after self-harm'},

    ]
export default data;
