const data = [
    { id : "Services", description : "Services and support", colour: '#C0504E' },
    { id : "Feelings", description : "Considering my feelings" , colour: '#4AACC6'},
    { id : "Events", description : "Events", colour: '#4F81BD' },
    { id : "Thoughts", description : "Considering my thoughts", colour: '#8064A2' },
    { id : "Behaviours", description : "Considering my behaviours", colour: '#FFFF99' },
    { id : "Afterwards", description : "After I self-harmed", colour: '#9BBB59'},

];

export default data;