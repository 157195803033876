let answerData = [
        { id : "6m", label : "6 months before", type : "timeline"},
        { id : "1m", label : "1 month before", type : "timeline"},
        { id : "1w", label : "1 week before", type : "timeline"},
        { id : "1d", label : "1 day before", type : "timeline"},
        { id : "1h", label : "1 hour before", type : "timeline"},
        { id : "sh", label : "I self-harmed", type : "timeline"},
        { id : "a", label : "Afterwards", type : "timeline"},
        { id : "ys", label : "Yes", type : "confirm"},
        { id : "no", label : "No", type : "confirm"},
        { id : "na", label : "Not applicable", type : "default"}
];

export default answerData;